body {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Opera */
}
.scrollbar-hide {
  -ms-overflow-style: none;  /* Internet Explorer and Edge */
  scrollbar-width: none;     /* Firefox */
}



@tailwind base;
@tailwind components;
@tailwind utilities;

/* CSS for overriding MUI day cells */
.css-1j3bv1k-MuiButtonBase-root-MuiPickersDay-root {
  border: 2px solid #D3D3D3 !important;
  border-top-left-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
  background-color: #fff !important;
  height: 48px !important;
  width: 48px !important;
}

.css-1j3bv1k-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
  border: 2px solid #4A4A4A !important;
}

/* Add shift colors if a shift is applied */
.shift-morning {
  background-color: #43C595 !important;
}
.shift-afternoon {
  background-color: #8bd248 !important;
}
.shift-evening {
  background-color: #FAC710 !important;
}
.shift-night {
  background-color: #FD474D !important;
}